import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useFormattedData from '../../../hooks/useFormattedData'
import '../solutions.scss'

const Data = () => {
  const data = useStaticQuery(graphql`
    query DataQuery {
      allContentfulSolution {
        nodes {
          id
          content {
            raw
          }
          contentfulid
        }
      }
    }
  `)

  const dataArray = data.allContentfulSolution.nodes.filter(
    (solution) => solution.contentfulid === 'Data'
  )
  const [index, activePage, getPrevious, getNext, length] = useFormattedData(
    dataArray[0].content
  )

  return (
    <div>
      <div className="leftSolutionPanel" />
      <div className="rightSolution">
        <div className="solutionContent">{activePage}</div>
        <div className="solutionButtonContainers">
          <button
            disabled={index === 0}
            className={index === 0 ? 'disabled arrowButton' : 'arrowButton'}
            onClick={() => getPrevious(index)}
          >
            ← Previous
          </button>
          <button
            disabled={index === length - 1}
            className={
              index === length - 1 ? 'disabled arrowButton' : 'arrowButton'
            }
            onClick={() => getNext(index)}
          >
            Next →
          </button>
        </div>
      </div>
    </div>
  )
}

export default Data
